<!-- Created by henian.xu on 2018/9/5. -->

<template>
    <page
        ref="page"
        back>
        <div
            class="second-header"
            slot="secondHeader"
        >
            <el-form
                ref="saveForm"
                class="npa-b"
                size="small"
                :inline="true"
                :model="saveFormModel"
                :rules="saveFormRules"
            >
                <el-form-item
                    prop="pageName"
                    label="页面名称"
                    v-if="pageType === 0 || pageType === 1 || pageType === 5"
                >
                    <el-input v-model="saveFormModel.pageName"/>
                </el-form-item>
                <el-form-item
                    label="是否启用"
                    v-if="pageType === 0 || pageType === 1 || pageType === 2 || pageType === 5"
                >
                    <el-switch v-model="saveFormModel.isAvailable"/>
                </el-form-item>
                <el-form-item
                    label="是否默认"
                    v-if="pageType === 0 || pageType === 5"
                >
                    <el-switch v-model="saveFormModel.isDefault"/>
                </el-form-item>
            </el-form>
            <el-button
                size="small"
                type="primary"
                @click="onSave(false)"
            >
                保存
            </el-button>
            <!--            <el-button
                            size="small"
                            type="success"
                            @click="onSave(true)"
                        >
                            保存并预览
                        </el-button>-->
            <el-button
                size="small"
                @click="onRestore"
            >
                还原初始状态
            </el-button>
        </div>

        <PageDecorator
            ref="pageDecorator"
            :available-components="availableComponents"
            :json="decorationJson"
        />

        <el-dialog
            title="扫一扫"
            width="300px"
            :visible.sync="QRCodeDialog.visible"
        >
            <img
                :src="qrcodeUrl"
                width="100%"
            >
        </el-dialog>
    </page>
</template>

<script>
import QRCode from 'qrcode';
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'DecorationEdit',
    mixins: [pagesMixin],
    data() {
        return {
            qrcodeUrl: '',
            saveFormModel: {
                id: '',
                businessDistrictId: '',
                pageName: '',
                isAvailable: false,
                isDefault: false,
            },
            decorationJson: undefined,
            saveFormRules: {
                pageName: {
                    required: true,
                    message: '不可为空',
                },
            },
            QRCodeDialog: {
                visible: false,
            },
            componentsType: 1,
        };
    },
    computed: {
        pageType() {
            return +this.$route.query.pageType;
        },
        availableComponents() {
            const { pageType } = this;
            const apiQuery = pageType === 5 ? { paymentMethod: '2' } : {};
            if (this.componentsType === 1) {
                return [
                    'Divider',
                    'Toolbar',
                    {
                        name: '商品',
                        data: {
                            componentType: 'Goods',
                            componentMargin: 0,
                            supportRowLayout: true,
                            supportAd: true,
                            supportWaterfall: true,
                            props: {
                                type: '1',
                                gapType: '1',
                                shadowProp: 31,
                                columns: '1',
                                ids: [],
                                apiQuery,
                                data: [],
                            },
                        },
                    },
                    {
                        name: '商品列表',
                        data: {
                            componentType: 'GoodsList',
                            componentMargin: 0,
                            supportRowLayout: true,
                            supportAd: true,
                            supportWaterfall: true,
                            props: {
                                type: '2',
                                gapType: '1',
                                shadowProp: 31,
                                columns: '1',
                                orderBy: 'onSaleTime,asc',
                                apiQuery,
                                data: [],
                            },
                        },
                    },
                    'Ad',
                    'AdCube',
                    'MagicCube',
                    'RichText',
                    'CustomCode',
                    'Video',
                    'Notification',
                    'Recommend',
                    'AffixTab',
                    'LimitTimeBuyActivity',
                ];
            }
            return [
                'Divider',
                'Toolbar',
                'Ad',
                'AdCube',
                'MagicCube',
                'RichText',
                'CustomCode',
                'Video',
                'Notification',
                'Recommend',
                'AffixTab',
            ];
        },
        adPageType() {
            return this.$route.query.adPageType;
        },
        distributorLevelId() {
            return this.$route.query.distributorLevelId;
        },
    },
    methods: {
        onSave(preview) {
            this.$refs.saveForm.validate().then(() => {
                this.$api.Sp.ShopDecoration.saveJsonData({
                    ...this.saveFormModel,
                    pageType: this.pageType,
                    JsonData: this.$refs.pageDecorator.getDecorationJson(),
                    // 在这里传剩余的参数如下
                    adPageType: this.adPageType,
                    distributorLevelId: this.distributorLevelId,
                }).then(json => {
                    const res = json.data;
                    this.saveFormModel.id = res.data.id;
                    if (preview) {
                        console.log(res);
                        QRCode.toDataURL(
                            res.data.buyerUrl,
                            {
                                margin: 2,
                                scale: 30,
                                errorCorrectionLevel: 'H',
                            },
                            (error, url) => {
                                if (error) {
                                    throw new Error(error);
                                }
                                this.qrcodeUrl = url;
                                this.QRCodeDialog.visible = true;
                            },
                        );
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                    }
                });
            });
        },
        onRestore() {
            this.$refs.pageDecorator.restoreInitial();
        },
    },
    created() {
        if (this.$route.params.id) {
            this.saveFormModel.id = this.$route.params.id;
            this.$api.Sp.ShopDecoration.getJsonData({ id: this.saveFormModel.id }).then(json => {
                const res = json.data.data || {};
                const decoration = res.decoration || {};
                this.decorationJson = decoration.pageContent;
                const { saveFormModel } = this;
                Object.keys(saveFormModel).forEach(key => {
                    saveFormModel[key] = decoration[key] || saveFormModel[key];
                });
            });
        }
        if (this.$route.query.copyId) {
            this.$api.Sp.ShopDecoration.getJsonData({ id: this.$route.query.copyId }).then(json => {
                const res = json.data.data || {};
                const decoration = res.decoration || {};
                this.decorationJson = decoration.pageContent;
                const { saveFormModel } = this;
                Object.keys(saveFormModel).forEach(key => {
                    saveFormModel[key] = decoration[key] || saveFormModel[key];
                });
                this.saveFormModel.id = null;
            });
        }
        if (this.$route.query.businessDistrictId) {
            this.saveFormModel.businessDistrictId = this.$route.query.businessDistrictId;
        }
        if (this.$route.query.businessDistrictId) {
            this.componentsType = 2;
        } else if (this.userData.appId !== 2) {
            this.componentsType = 2;
        }
    },
};
</script>

<style lang="scss">
.decoration-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .ctrl {
        /*position: fixed;*/
        /*right: 18px;*/
        /*width: 400px;*/
    }
}
</style>
